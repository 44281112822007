

import ContentCard from 'portal/components/common/content-card/ContentCard.vue';
import ContentCardSwiper
    from 'portal/components/common/content-card/ContentCardSwiper.vue';


export default {
    name: 'OneToFiveBlock',
    components: { ContentCardSwiper, ContentCard },

    props: {
        bigCard: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        smallCards: {
            type: Array,
            required: true,
            default: () => [],
        },

        firstScreenBlock: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasBigCard() {
            return Object.keys(this.bigCard).length > 0;
        },
    },
};
