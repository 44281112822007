// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OneToFiveBlock_tFnv3{display:grid;gap:2rem;grid-template-columns:repeat(2,1fr)}.cardsWrapper_x0hbO{display:grid;gap:2rem;grid-template-rows:repeat(5,1fr)}@media(max-width:767.98px){.OneToFiveBlock_tFnv3{display:flex;flex-direction:column;gap:0;margin-bottom:4rem;max-width:90vw}.firstContainer_JWAgq{margin-right:0;max-width:100%}.cardsWrapper_x0hbO{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"OneToFiveBlock": "OneToFiveBlock_tFnv3",
	"cardsWrapper": "cardsWrapper_x0hbO",
	"firstContainer": "firstContainer_JWAgq"
};
module.exports = ___CSS_LOADER_EXPORT___;
