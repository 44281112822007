
import VSwiper from 'portal/components/ui/swiper/VSwiper.vue';
import ContentCard from 'portal/components/common/content-card/ContentCard.vue';

import { chunkArray } from 'portal/assets/js/helpers/helpers';

export default {
    name: 'ContentCardSwiper',
    components: { ContentCard, VSwiper },
    props: {
        smallCards: {
            type: Array,
            required: true,
        },

        bigCard: {
            type: Object,
            default: null,
        },

        addBigCardToSlider: {
            type: Boolean,
            default: false,
        },

        deleteFirstCard: {
            type: Boolean,
            default: false,
        },

        readTime: {
            type: Number,
            default: null,
        },

        size: {
            type: String,
            default: 'small',
        },

        nonLazyImage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        sliderCards() {
            const sliderCards = [...this.smallCards];

            if (this.$deviceIs.mobile && this.addBigCardToSlider) {
                sliderCards.push(this.bigCard);
            } else if (this.$deviceIs.mobile && this.deleteFirstCard) {
                sliderCards.shift();
            }

            return chunkArray(sliderCards, 2);
        },

        classList() {
            return {
                [this.$style['_medium']]: this.deleteFirstCard,
            };
        },

    },

};
